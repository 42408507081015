import * as React from "react";
import {
  makeStyles,
  Paper,
  Typography,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
} from "@material-ui/core";
import withRatio from "../util/useRatio";
import { Media } from "@yardzen-inc/models";
import { PaperProps } from "@material-ui/core/Paper";
import usePrevious from "../util/hooks/usePrevious";
import { FileCopy } from "@material-ui/icons";
const classnames = require("classnames");

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    borderRadius: 0,
    width: "100%",
    height: "100%",
    flexFlow: "column-reverse nowrap",
    pointerEvents: "all",
    transitionProperty: "opacity, height",
    transition: "all 250ms",
  },
  out: {
    opacity: 0,
    display: "none",
  },
  toolBar: {
    height: "15%",
    minHeight: "fit-content",
    backgroundColor: "#4f4d4cEF",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "flex-end",
    cursor: "default",
    zIndex: 7,
  },
  title: {
    fontSize: "2rem",
    color: "white",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    paddingTop: "0.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  playBackSpeedSelect: {
    color: "white",
  },
}));

export interface MediaCardProps {
  style?: React.CSSProperties;
  id?: string;
  media: Media;
  className?: string;
  ratio?: [number, number]; //x y
  onImageClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  titleStyle?: React.CSSProperties;
  titleClass?: string;
  giveRef?: (ref: React.RefObject<HTMLDivElement> | null) => void;
  noTitle?: boolean;
  noLoad?: boolean;
  title?: string;
  noToolBar?: boolean;
  loading?: boolean;
  childrenAbove?: React.ReactNode | React.ReactNode[];
  childrenBelow?: React.ReactNode | React.ReactNode[];
  childrenToolBar?: React.ReactNode | React.ReactNode[];
  toolbarStyles?: React.CSSProperties;
  out?: boolean;
  height?: string | number;
  giveRect?: (rect: ClientRect | null) => void;
  paperProps?: PaperProps;
  disableThumbnail?: boolean;
}

const MediaCard = (props: MediaCardProps) => {
  const classes = useStyles();
  const rootRef = React.useRef<HTMLDivElement>(null);
  const [playbackSpeed, setPlaybackSpeed] = React.useState<number>(1);
  const [imageURL, setImageURL] = React.useState<String>("");

  React.useEffect(() => {
    if (props.giveRef) {
      props.giveRef(rootRef || null);
    }
  }, [rootRef]);

  React.useEffect(() => {
    const vid = document.getElementById(`${props.media.id}-embed`);
    if (!vid) return;
    (vid as HTMLVideoElement).playbackRate = playbackSpeed;
  }, [playbackSpeed]);

  const rect = (
    props.height === undefined
      ? () =>
          withRatio({
            dimension: "height",
            ratio: props.ratio || [16, 9],
            ref: rootRef,
          })
      : () => null
  )();

  const prevRect = (
    props.height === undefined
      ? () => usePrevious<ClientRect | null>(rect)
      : () => null
  )();

  const height = React.useMemo(() => {
    if (props.height) {
      if (typeof props.height !== "string") {
        return props.height.toString() + "px";
      }
    }

    if (rect) {
      return rect.height.toString() + "px";
    }

    if (prevRect && !rect) {
      return prevRect.height.toString() + "px";
    }

    return null;
  }, [(rect && rect.height) || props.height]);

  const prevHeight = usePrevious(height);

  React.useEffect(() => {
    if (props.giveRect) {
    }
  }, [height]);

  const usePdf = React.useMemo(
    () => props.media.fileType.toLowerCase().includes("pdf"),
    [props.media]
  );

  const useVideo = React.useMemo(
    () => props.media.fileType.toLowerCase().includes("video"),
    [props.media]
  );

  const useImage = React.useMemo(
    () => props.media.fileType.toLowerCase().includes("image"),
    [props.media]
  );

  React.useEffect(() => {
    const { thumbnailURL, downloadURL } = props.media;
    setImageURL(
      !!thumbnailURL && !props.disableThumbnail ? thumbnailURL : downloadURL
    );
  }, [props.media]);

  return (
    <div
      style={{
        height: height || prevHeight || "unset",
        ...props.style,
        width: "100%",
        transitionProperty: "height width opacity",
        transition: "all 200ms",
      }}
      id={props.id}
      ref={rootRef}
    >
      <Paper
        className={classnames(
          classes.root,
          props.className ? props.className : "",
          props.out ? classes.out : ""
        )}
        elevation={5}
        onClick={props.onImageClick}
        data-url={`url("${imageURL}")`}
        {...(props.paperProps ? props.paperProps : {})}
        style={{
          height: height || prevHeight || "unset",
          ...((props.paperProps && props.paperProps.style) || {}),
          width: "100%",
          backgroundImage: !!(props.noLoad || usePdf)
            ? "none"
            : `url("${imageURL}")`,
          backgroundColor: "black",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          cursor: props.onImageClick ? "pointer" : "default",
        }}
      >
        {props.childrenAbove || null}
        {!props.noToolBar && (
          <div className={classes.toolBar} style={props.toolbarStyles}>
            {props.childrenToolBar || null}
            {!props.noTitle && (
              <Typography
                noWrap
                component="h3"
                style={props.titleStyle}
                className={classnames(classes.title, props.titleClass)}
              >
                {props.title || props.media.originalFileName}
              </Typography>
            )}
            {useVideo && (
              <div style={{ alignSelf: "center", marginRight: "0.5rem" }}>
                <FormControl
                  style={{ color: "white !important" }}
                  className={classes.playBackSpeedSelect}
                  hiddenLabel
                >
                  <InputLabel
                    style={{ fontSize: "0.75rem", color: "white" }}
                    id="video-playback-select-label"
                  >
                    Playback Speed
                  </InputLabel>

                  <Select
                    labelId="video-playback-select-label"
                    id="playback-select"
                    value={playbackSpeed}
                    style={{ color: "white" }}
                    onChange={(e) => setPlaybackSpeed(e.target.value as number)}
                  >
                    <MenuItem value={0.5}>0.5</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={1.5}>1.5</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
          </div>
        )}
        {props.loading && <LinearProgress variant="indeterminate" />}
        {usePdf && renderPdf(props.media, rect, props.noToolBar)}
        {useVideo && renderVideo(props.media, rect)}
        {!!(!useImage && !usePdf && !useVideo) && renderPlaceholder(rect)}
        {props.childrenBelow || null}
      </Paper>
    </div>
  );

  function renderVideo(
    media: Media,
    rect?: ClientRect | null
  ): React.ReactNode | null {
    if (!rect) {
      return null;
    }
    return (
      <video
        controls
        style={{
          flexGrow: 1,
          backgroundColor: "black",
          width: "100%",
          maxHeight: "75%",
        }}
        src={media.downloadURL}
        id={`${media.id}-embed`}
      />
    );
  }
};

function renderPdf(
  media: Media,
  rect?: ClientRect | null,
  noToolBar?: boolean
): React.ReactNode | null {
  if (!rect) {
    return null;
  }
  return (
    <iframe
      style={{
        flexGrow: 1,
        height: noToolBar ? "100%" : "unset",
      }}
      width={rect.width}
      src={media.downloadURL}
    />
  );
}

function renderPlaceholder(
  rect?: ClientRect | null,
  noToolBar?: boolean
): React.ReactNode | null {
  if (!rect) {
    return null;
  }
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      style={{
        flexGrow: 1,
        height: noToolBar ? "100%" : "unset",
      }}
      width={rect.width}
    >
      <FileCopy
        style={{
          color: "white",
          height: rect.width / 4,
          width: rect.width / 4,
        }}
      />
    </Box>
  );
}

MediaCard.defaultProps = {
  style: {},
};

export default MediaCard;
